.productCategoryCardSet {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;

  @media screen and (min-width: 600px) {
    justify-content: space-between;
  }
}
