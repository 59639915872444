.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(100%, 600px);

  & div:nth-child(7) {
    margin-top: 50px;
    align-self: flex-end;
  }
}
