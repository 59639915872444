.pageTitle {
  display: block;
  font-weight: 500;
  color: #f8f8f8;
  width: min(90%, 330px);
  font-size: 24px;
  text-transform: none;
  text-align: center;
  margin-bottom: 40px;
  z-index: 30;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;

  @media screen and (min-width: 600px) {
    width: 28rem;
    font-size: 2rem;
  }

  @media screen and (min-width: 1200px) {
    width: 600px;
    font-size: 38px;
    letter-spacing: 0.5px;
  }

  @media screen and (min-width: 1500px) {
    font-size: 42px;
    width: 620px;
  }
}
