.productCategoryTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: start;
  margin-bottom: 80px;
}

.productCategoryTitle__title {
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: -1px;
  font-weight: bold;
  color: #132142;

  @media screen and (min-width: 600px) {
    font-size: 46px;
  }
}

.productCategoriTitle__line {
  width: 34%;
  height: 5px;
  background-color: #e0b32d;

  @media screen and (min-width: 600px) {
    width: 40%;
    height: 10px;
  }
}
