.sliderSection {
  height: 90vh;
  margin-bottom: 60px;

  @media screen and (min-width: 900px) {
    margin-bottom: 0px;
  }
}

.withBlueBgCont {
  height: 90%;
  width: 100%;
  background-color: #132142;
  display: flex;
  justify-content: center;
}

.ImgCont {
  position: relative;
  display: flex;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow-y: hidden;
  width: 100%;
}
