.productsNavlink {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    width: 50%;
    height: 50%;
    object-fit: contain;
    cursor: pointer;

    @media screen and (min-width: 1800px) {
      width: 60%;
      height: 60%;
    }
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.12);
    cursor: pointer;

    @media screen and (min-width: 1800px) {
      width: 100px;
      height: 100px;
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.blueBg {
  cursor: pointer;
  background-color: #132142;
  transition: all 0.15s linear;

  &:hover {
    background-color: #e0b32d;
  }

  & img {
    filter: brightness(15%) invert(1);
    width: 40%;
    height: 40%;

    @media screen and (min-width: 1800px) {
      width: 55%;
      height: 55%;
    }
  }
}

.blueLogo {
  filter: invert(50%) sepia(31%) saturate(1204%) hue-rotate(189deg) brightness(27%) contrast(93%);
}

.smallLogo {
  width: 60%;
  height: 60%;
  object-fit: contain;
}

.hoverEffect {
  transition: var(--transition);

  &:hover {
    background-color: #e0b32d;

    & img {
      filter: brightness(15%) invert(1);
    }
  }
}
