.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #132142;
  text-transform: none;
  letter-spacing: -1px;
  text-align: center;

  @media screen and (min-width: 1500px) {
    margin-bottom: 20px;
  }
}
