.tableNavbar {
  position: relative;

  &__buttonCont {
    position: absolute;
    top: max(150%, 70px);
    left: 50%;
    transform: translateX(-50%);
  }

  &__dropdown {
    position: absolute;
    //min-height: 70px;
    //top: max(300%, 130px);
    top: 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    max-height: 450px;
    overflow-y: overlay;

    animation: dropdowBox 0.5s ease-in-out forwards;
    @keyframes dropdowBox {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
        top: max(300%, 130px);
      }
    }

    & span {
      cursor: pointer;
      margin-bottom: 20px;

      animation: dropdown 1s ease-in-out forwards;
      @keyframes dropdown {
        0% {
          opacity: 0;
          top: 0;
          position: relative;
          left: 0;
        }

        20% {
          opacity: 1;
          top: 0;
          position: relative;
          left: 0;
        }

        40% {
          opacity: 1;
          top: 0;
          position: relative;
          left: 0;
        }

        60% {
          opacity: 1;
          top: 0;
          position: relative;
          left: 0;
        }

        100% {
          opacity: 1;
          top: 0;
          position: relative;
          left: 0;
        }
      }
    }
  }
}
