.imgContainer {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ece8d8;
  border-radius: 50%;
  margin-bottom: 20px;

  @media screen and (min-width: 1500px) {
    margin-bottom: 20px;
  }

  &__img {
    width: 75px;
    height: 75px;
  }
}
