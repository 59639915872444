.drawer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  @media screen and (min-width: 900px) {
    display: none;
  }

  &__navbar {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 0px;
    top: 0px;
    width: 75vw;
    height: 100vh;
    z-index: 100;
    padding: 0 20px;
    box-shadow: -1px 0px 1px hsl(0, 0%, 0%, 20%);
    background-color: #f8f8f8;
    transition: var(--transition);
  }

  &__navList {
    width: 100%;
  }

  &__itemCont {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 20px;

    & > a {
      margin-left: 0;
    }

    & > a li {
      font-size: 20px;
    }
  }

  &__linksCont {
    position: absolute;
    display: flex;
    flex-flow: row wrap;
    z-index: 100;
    bottom: 20px;
    left: 30px;
    width: 80px;
  }

  &__show {
    transform: translateX(0);
  }

  &__hide {
    transform: translateX(105%);
  }

  &__focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 95;
  }

  &__showFocus {
    display: block;
    background-color: hsl(0, 0%, 0%, 20%);
    backdrop-filter: blur(5px);
    animation: show 0.5s ease-in-out forwards;
  }

  &__hideFocus {
    display: none;
    opacity: 0;
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
