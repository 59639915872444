.FAQCard {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    text-align: center;
    color: #132142;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0px;
  }
}

.normal {
  font-size: 20px;
}

.large {
  font-size: 26px;
}

.grey {
  color: #a2a2a2;
}
