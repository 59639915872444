.socialLinkSet {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  align-self: flex-start;
  width: fit-content;
  margin-bottom: 20px;
  order: 2;

  @media screen and (min-width: 600px) {
    justify-content: center;
    align-self: center;
    width: 100%;
    margin: 40px 0 0 0;
  }

  @media screen and (min-width: 900px) {
    margin-bottom: 0px;
  }

  &__isInDrawer {
    margin: 0;
  }
}
