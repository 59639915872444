.tableNavbarButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 3px 3px 2px rgb(0 0 0 / 12%);
  background-color: #f8f8f8;
  cursor: pointer;

  &__arrow {
    font-size: 32px;
  }
}

.up {
  animation: fadeOutRotateUpFadein 0.5s ease-in-out forwards;
  @keyframes fadeOutRotateUpFadein {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }

    30% {
      opacity: 0;
      transform: rotate(0deg);
    }

    60% {
      opacity: 0;
      transform: rotate(180deg);
    }

    100% {
      opacity: 1;
      transform: rotate(180deg);
    }
  }
}

.down {
  animation: fadeOutRotateDownFadein 0.5s ease-in-out forwards;
  @keyframes fadeOutRotateDownFadein {
    0% {
      opacity: 1;
      transform: rotate(180deg);
    }

    30% {
      opacity: 0;
      transform: rotate(180deg);
    }

    60% {
      opacity: 0;
      transform: rotate(0deg);
    }

    100% {
      opacity: 1;
      transform: rotate(0deg);
    }
  }
}
