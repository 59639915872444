.Large {
  width: 220px;

  @media screen and (min-width: 600px) {
    width: 260px;
  }
}

.Normal {
  width: 90px;

  @media screen and (min-width: 600px) {
    width: 100px;
  }
}
