.FAQ {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 60px 20px;

  @media screen and (min-width: 900px) {
    height: 90vh;
  }

  &__title {
    font-size: 24px;
    font-weight: 400;
    color: #132142;
    letter-spacing: 4px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__btnCont {
    margin-top: 40px;
  }
}
