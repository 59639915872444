.left {
  z-index: 5;
  opacity: 0;
}

.right {
  z-index: 5;
  opacity: 0;
}

.active {
  z-index: 5;
  opacity: 1;
}

.inFAQLeft {
  z-index: 5;
  opacity: 0;
  left: -100%;
}

.inFAQRight {
  z-index: 5;
  opacity: 0;
  left: 100%;
}

.inFAQActive {
  z-index: 5;
  opacity: 1;
  left: 0;
}
