.roundButton {
  padding: 12px 15px;
  background-color: #e0b32d;
  border: 1px solid #e0b32d;
  border-radius: 50px;
  font-size: 16px;
  color: #132142;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  font-family: inherit;
  z-index: 10;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: #f8f8f8;
  }

  &:disabled {
    background-color: #705c1f;
    border: 1px solid #705c1f;
    color: #132142;
    cursor: not-allowed;
  }

  @media screen and (min-width: 600px) {
    padding: 15px 30px;
  }

  @media screen and (min-width: 1800px) {
    font-size: 20px;
  }
}

.nonShaked {
  align-self: flex-end;
}

.small {
  padding: 8px 15px;
}

.dark {
  &:hover {
    background-color: #132142;
    border: 1px solid #132142;
    color: #f8f8f8;
  }
}

.inFAQ {
  font-size: 14px;
  padding: 6px 15px;
}
