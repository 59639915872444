.sliderButtonsCont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
}

.activeBtn {
  background-color: #e0b32d;
}
