.productsHeader {
  background-color: #f8f8f8;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12);
  z-index: 100;
  grid-area: productsHeader;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  height: max(120px, 10vh);
  position: fixed;
}
