.routeAnimationLoader {
  background-color: #152244;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105%;
  height: 105%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  opacity: 0;
}

.fadeIn {
  animation: fadeIn 1s ease-in-out forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.fadeOut {
  animation: fadeOut 1s ease-in-out;
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.displayNone {
  display: none;
}
