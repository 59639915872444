.productImgSet {
  width: min(100%, 280px);
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  margin: 10px 0 35px 0;
  z-index: 10;

  @media screen and (min-width: 600px) {
    width: min(100%, 700px);
  }

  @media screen and (min-width: 1800px) {
    width: 1000px;
  }
}
