.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 40px;
  width: 100px;
  height: 100px;
  background-color: #f8f8f8;
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;
  transition: var(--transition);
  z-index: 5;

  &:hover {
    box-shadow: 0 5px 10px #021033;

    & .name {
      transform: translate(-50%, 0%);
      background-color: #e0b32d;
      font-size: 12px;
      opacity: 1;
    }

    & .productsImg {
      transform: scale(1.1);
      filter: drop-shadow(0px 0px 5px 3px rgba(0, 0, 0, 1));
    }
  }

  @media screen and (min-width: 900px) {
    margin-bottom: 10px;
  }

  @media screen and (min-width: 1800px) {
    margin-bottom: 10px;
    width: 125px;
    height: 125px;
  }
}

.name {
  position: absolute;
  display: block;
  top: 110%;
  left: 50%;
  font-size: 6px;
  font-weight: 600;
  z-index: -1;
  opacity: 0;
  transform: translate(-50%, -50%);
  text-align: center;
  width: max-content;
  padding: 5px;
  color: #142143;
  text-transform: uppercase;
  transition: all 0.2s ease;
}

.productsImg {
  width: 60px;
  height: 60px;
  transition: var(--transition);

  @media screen and (min-width: 1800px) {
    width: 80px;
    height: 80px;
  }
}
