.homeTemplateCont {
  display: grid;
  grid:
    [row1-start] 'header' max(70px, 10vh) [row1-end]
    [row2-start] 'pageContent' calc(100vh - max(70px, 10vh)) [row2-end]
    / auto;
  min-height: 100%;
}

.homeTemplate {
  grid-area: pageContent;
  overflow-y: auto;
  scroll-behavior: smooth;
  grid:
    [row1-start] 'main' auto [row1-end]
    [row2-start] 'footer' auto [row2-end]
    / auto;
}

.main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
  background-image: url('../../../assets/img/whoBgImg/boxeDown.svg'),
    url('../../../assets/img/whoBgImg/boxeUp.svg');
  background-repeat: no-repeat, no-repeat;
  background-size: 90px, 90px;
  background-position: 90vw 16vh, 4vw 82vh;
  background-attachment: fixed, fixed;
}
