.sliderImg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1067px;
  object-fit: cover;
  left: -90%;
  margin: 0;
  transition: all 2s linear;

  @media screen and (min-width: 600px) {
    left: 0;
    width: 100%;
    object-position: top center;
  }
}

.faq {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 100%;
  margin: 0;
  transition: all 1s ease-in-out;

  &__img {
    width: 110px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 70px;
  }

  &__text {
    text-transform: none;
    width: min(592px, 90%);
    font-size: 24px;
    font-weight: 300;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 0px;
    height: 90px;
    color: #a2a2a2;

    @media screen and (min-width: 600px) {
      font-size: 36px;
      height: auto;
    }
  }
}
