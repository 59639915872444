.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(100%, 600px);

  & div:nth-child(7) {
    margin-top: 50px;
    align-self: flex-end;
  }
}

.buttonsCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  & .buttonOut {
    text-decoration: underline;
    color: #928d8d;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      color: #777676;
    }
  }
}
