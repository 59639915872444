.productTemplateCont {
  display: grid;
  grid:
    [row1-start] 'header' max(70px, 10vh) [row1-end]
    [row2-start] 'pageContent' calc(100vh - max(70px, 10vh)) [row2-end]
    / auto;
  min-height: 100%;
}

.productsTemplate {
  grid-area: pageContent;
  overflow-y: auto;
  grid:
    [row1-start] 'main' auto [row1-end]
    [row2-start] 'footer' auto [row2-end]
    / auto;
}

.main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

.contentCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: min(100%, 400px);
  padding: 0px 20px;
  margin: 150px 0 75px 0;

  @media screen and (min-width: 600px) {
    padding: 0 40px;
    margin: 150px 0 75px 0;
    width: 100%;
    max-width: 1730px;
  }

  @media screen and (min-width: 900px) {
    padding: 0 90px;
  }
}
