.faqBeginBtn {
  outline: none;
  border: none;
  color: #f9f9f9;
  font-size: 20px;
  font-weight: 500;
  padding: 20px 30px;
  border-radius: 50px;
  background-color: #132142;
  cursor: pointer;
  transition: var(--transition-fast);

  &:hover {
    background-color: #e0b32d;
  }
}
