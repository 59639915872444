.cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.left {
  align-items: flex-start;
}

.center {
  align-items: center;
}

.right {
  align-items: flex-end;
}

.title {
  display: block;
  line-height: 1.2;
  margin-bottom: 0px;
  text-transform: none;
  letter-spacing: -2px;
  color: #132142;
}

.smallText {
  font-size: 22px;
}

.mediumText {
  font-size: 28px;
}

.largeText {
  font-size: 34px;

  @media screen and (min-width: 1500px) {
    font-size: 38px;
  }

  @media screen and (min-width: 1500px) {
    font-size: 46px;
  }
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.line {
  width: 60px;
  height: 4px;
  margin: 23px 0;
  border-radius: 5px;
}

.blueLine {
  background-color: #132142;
}

.yellowLine {
  background-color: #e0b32d;
}

.yellowText {
  color: #e0b32d;
}

.blueText {
  color: #132142;
}

.whiteText {
  color: #f8f8f8;
}

.light {
  font-weight: 400;
}

.normal {
  font-weight: 500;
}

.bold {
  font-weight: 600;
}
