.ProductCategoryCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: min(100%, 400px);
  margin-bottom: 25px;
  padding: 15px;
  border: 2px solid #e2e2e2;
  border-radius: 15px;
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    & img {
      filter: grayscale(0%);
    }
  }
}

.small {
  width: min(100%, 290px);

  & span {
    font-size: 18px;
  }

  & p {
    font-size: 18px;
    text-align: left;
    line-height: normal;
    height: 50px;
    overflow: hidden;
    position: relative;
  }
}
