.whoAreWeSection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    background-image: url('../../../assets/img/whoBgImg/circle.svg');
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: 130px center;
    right: 0%;
    bottom: -20%;
  }

  @media screen and (min-width: 900px) {
    height: 90vh;
    margin-bottom: 0px;
  }
}

.whoAreWe {
  width: min(90%, 450px);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 900px) {
    justify-content: space-between;
    width: min(90%, 980px);
  }

  @media screen and (min-width: 1500px) {
    justify-content: space-between;
    width: 1200px;
  }
}
