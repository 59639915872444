.formTextArea {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__textArea {
    padding: 5px 0;
    margin: 5px 0px 30px 0px;
    height: 90px;
    background-color: #0f1c3d;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #d9dce0;
    font-size: 16px;
    border-bottom: 1px solid gray;
    resize: none;

    &:focus {
      border-bottom: 1px solid #dee0e6;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: #80808050;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #132142;
      border-radius: 5px;
    }
  }

  &__label {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    color: #928d8d;
    margin: 5px 0px;
  }

  &__span {
    text-align: left;
    font-size: 16px;
    color: rgb(199, 43, 43);
    display: none;
    position: absolute;
    bottom: 5px;
  }

  &__textArea[data-focused='true'] {
    border-bottom: 1px solid rgb(216, 25, 25);
  }

  &__textArea[data-focused='true'] ~ span {
    display: block;
  }
}

.FAQ {
  & textarea {
    font-size: 18px;
    margin-bottom: 30px;
    text-align: left;
    color: #4e5871;
    height: 80px;
    background-color: #e7e7e7;
    border-bottom: 1px solid rgb(197, 192, 192);

    &:focus {
      border-bottom: 1px solid #222d47;
    }
  }

  & span {
    font-size: 14px;
    position: absolute;
    bottom: -5px;
  }
}

.smallLabel {
  font-size: 14px;
  margin-bottom: 10px;
}
