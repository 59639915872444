.industries {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 60px 20px;

  @media screen and (min-width: 900px) {
    height: 90vh;
  }
}

.interestsContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 500px;
  }

  @media screen and (min-width: 900px) {
    width: min(90%, 900px);
    justify-content: space-between;
  }

  @media screen and (min-width: 1200px) {
    width: min(90%, 1200px);
  }

  @media screen and (min-width: 1500px) {
    margin-top: 50px;
  }

  @media screen and (min-width: 1800px) {
    margin-top: 80px;
  }
}
