.productBanner {
  position: relative;
  width: 100%;
  height: 75vh;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(19, 33, 66);
    background: linear-gradient(90deg, rgba(19, 33, 66, 90) 0%, rgba(255, 255, 255, 0) 100%);

    @media screen and (min-width: 600px) {
      background: linear-gradient(90deg, rgba(19, 33, 66, 90) 0%, rgba(255, 255, 255, 0) 66%);
    }
  }

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  & div {
    position: absolute;
    top: 55%;
    transform: translateY(-45%);
  }
}
