.navList {
  display: none;

  @media screen and (min-width: 900px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.navIsInFooter {
  @media screen and (min-width: 600px) {
    display: flex;
    order: 1;
  }
}

.isInFooter {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    align-items: flex-end;
  }

  a {
    display: block;
    margin-bottom: 40px;
    margin-left: 0;
    text-align: center;

    @media screen and (min-width: 600px) {
      margin-bottom: 5px;
    }

    @media screen and (min-width: 900px) {
      margin-left: 14px;
      text-align: left;
    }

    span {
      color: #707070;
      transition: var(--transition);

      &:hover {
        color: #cda429;
      }

      @media screen and (min-width: 900px) {
        font-size: 14px;
      }

      @media screen and (min-width: 1800px) {
        font-size: 16px;
      }
    }
  }
}
