.linkCont {
  margin-left: 22px;
  position: relative;

  &:hover {
    .dropdownCont {
      display: block;
    }
  }
}

.link {
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #132142;
  transition: var(--transition);

  @media screen and (min-width: 1800px) {
    font-size: 20px;
  }

  &:hover {
    color: #6b7488;
  }
  /*
  & span {
    
  }
  */
}

.dropdownCont {
  position: absolute;
  display: none;
  background-color: #f8f8f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  top: 100%;
  left: 0;
  width: 170px;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
}
