.Products {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #142143;
  position: relative;
  padding: 60px 20px;

  &::before {
    content: '';
    position: absolute;
    width: 250px;
    height: 250px;
    bottom: -15%;
    left: -30%;
    background-image: url('../../../assets/img/products/circle.svg');
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: center;

    animation: rotate-center 5s cubic-bezier(0.3, 0.2, 0.2, 0.8) infinite forwards;
    @keyframes rotate-center {
      0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
      }

      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @media screen and (min-width: 600px) {
      width: 300px;
      height: 300px;
      background-size: 300px;
      bottom: -30%;
      left: -15%;
    }

    @media screen and (min-width: 900px) {
      width: 400px;
      height: 400px;
      background-size: 400px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 150px;
    height: 150px;
    top: 16%;
    right: -15%;
    background-image: url('../../../assets/img/products/box.svg');
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: center;

    animation: rotate-center 5s cubic-bezier(0.3, 0.2, 0.2, 0.8) infinite forwards;
    @keyframes rotate-center {
      0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
      }

      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @media screen and (min-width: 600px) {
      width: 200px;
      height: 200px;
      background-position: center;
      background-size: 300px;
    }

    @media screen and (min-width: 900px) {
      width: 330px;
      height: 330px;
      background-position: center;
      background-size: 513px;
      top: 0%;
    }

    @media screen and (min-width: 1200px) {
      top: 10%;
      width: 400px;
      height: 400px;
      background-size: 650px;
      background-position: center;
    }
  }

  @media screen and (min-width: 900px) {
    height: 90vh;
  }
}

.text {
  color: #f8f8f8;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 60px;
  text-align: center;
  line-height: 30px;
  z-index: 10;
}
