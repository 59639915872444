.BannerInfo {
  display: flex;
  flex-direction: column;
  width: min(100%, 400px);
  padding: 10px 20px;

  @media screen and (min-width: 600px) {
    padding: 60px 40px;
    width: 100%;
    max-width: 1730px;
  }

  @media screen and (min-width: 900px) {
    padding: 20px 90px;
  }
}
