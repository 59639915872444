.interest {
  width: min(100%, 170px);
  margin: 0 30px 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 900px) {
    margin: 0 5px 40px 5px;
  }

  @media screen and (min-width: 1200px) {
    width: min(100%, 240px);
  }
}
