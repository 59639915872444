.cont {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 45%;
}

.beginBtn {
  position: relative;
  background-color: transparent;
  font-size: 24px;
  color: #fff;
  border: 2px solid #e0b32d;
  padding: 12px 18px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  transition: var(--transition);
  transition-delay: 0.4s;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e0b32d;
    transition: all 0.15s ease-in-out;
    transition-delay: 0.4s;
    z-index: -1;
  }

  &:hover {
    color: #132142;
    border: 2px solid transparent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    &:after {
      top: 0%;
    }
  }

  @media screen and (max-height: 420px) {
    transition-delay: 0.2s;
  }

  &:hover ~ .vertilcalLine {
    z-index: -1;
    animation: lineGrowing 0.6s ease-in-out;
    @keyframes lineGrowing {
      0% {
        height: 0%;
      }
      40% {
        height: 60%;
      }
      100% {
        height: 0%;
        top: 34px;
        bottom: auto;
      }
    }

    @media screen and (max-height: 600px) {
      animation: lineGrowing 0.8s ease-in-out;
      @keyframes lineGrowing {
        0% {
          height: 0%;
        }
        40% {
          height: 50%;
        }
        100% {
          height: 0%;
          top: 34px;
          bottom: auto;
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    font-size: 28px;
  }

  @media screen and (min-width: 1800px) {
    font-size: 36px;
  }
}

.vertilcalLine {
  position: absolute;
  top: 100%;
  left: 45%;
  transform: translate(-50%, -50%);
  width: 3px;
  height: 0%;
  background-color: #e0b32d;
  margin: 0 10px;
  z-index: 60;
  transition: var(--transition);
}
