.footer {
  grid-area: footer;
  background-color: #050e21;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 600px) {
    justify-content: center;
  }

  &__innerCont {
    height: 100%;
    width: min(100%, 400px);
    padding: 60px 20px;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 600px) {
      justify-content: space-between;
      flex-flow: row wrap;
      padding: 60px 40px;
      width: 100%;
      max-width: 1730px;
    }

    @media screen and (min-width: 900px) {
      padding: 20px 90px;
    }
  }

  &__leftContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-center;
    justify-content: space-evenly;
    height: 160px;
    text-align: center;
    width: fit-content;

    @media screen and (min-width: 600px) {
      justify-content: space-between;
      text-align: left;
      margin-bottom: 0px;
      order: 0;
      height: 165px;
    }

    @media screen and (min-width: 900px) {
      height: 133px;
    }

    p {
      display: block;
      font-size: 14px;
      line-height: normal;
      color: #f8f8f8;

      @media screen and (min-width: 1800px) {
        font-size: 16px;
      }
    }

    & p:nth-child(1) {
      margin-bottom: 10px;
      font-weight: 300;

      @media screen and (min-width: 600px) {
        justify-content: space-between;
        text-align: left;
        margin-bottom: 0px;
        line-height: 22px;
      }

      @media screen and (min-width: 900px) {
        justify-content: space-between;
        text-align: left;
        margin-bottom: 0px;
        line-height: 18px;
      }
    }

    & p:nth-child(2) {
      margin-bottom: 10px;
      font-weight: 400;

      @media screen and (min-width: 900px) {
        margin-bottom: 0px;
      }
    }

    & p:nth-child(3) {
      font-size: 12px;
      margin-bottom: 0px;

      @media screen and (min-width: 600px) {
        line-height: 0px;
      }

      & span {
        display: inline-block;
        font-size: 12px;
        color: #e0b32d;
      }

      @media screen and (min-width: 1800px) {
        font-size: 16px;
      }
    }
  }
}
