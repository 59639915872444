.ProductCategoryLabel {
  display: block;
  min-height: 50px;
  font-size: 18px;
  letter-spacing: -1px;
  font-weight: 700;
  margin: 10px 0;
  color: #132142;
  text-transform: capitalize;
}
