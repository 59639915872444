.pageLogoCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 15px 0;
}

.pageLogo {
  height: 100%;
  width: auto;
}
