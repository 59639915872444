.productsNavbar {
  display: none;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 600px) {
    display: flex;
  }

  &__home {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 100%;

    @media screen and (min-width: 1800px) {
      width: 200px;
    }
  }

  &__categories {
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    width: calc(100vw - 300px);
  }

  &__categories::-webkit-scrollbar {
    height: 6px;
  }

  &__categories::-webkit-scrollbar-track {
    height: 8px;
    width: 8px;
    background-color: #d5d5d5;
  }

  &__categories::-webkit-scrollbar-thumb {
    height: 8px;
    width: 8px;
    background-color: #132142;
    border-radius: 5px;
  }

  &__categoryCont {
    margin: 0 30px;
  }

  &__category {
    &:hover ~ div {
      display: block;
      animation: fadeIn 0.5s ease-in-out forwards;
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  &__labelCont {
    display: none;
    opacity: 0;
    position: absolute;
    top: 120%;
    transform: translate(-60%, 30%);
    margin-left: 15px;
  }

  &__goBack {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 100%;

    @media screen and (min-width: 1800px) {
      width: 200px;
    }
  }
}
