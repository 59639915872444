.productSubCategoryCardSet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: var(--transition);

  & > div {
    display: none;
  }

  & > div:nth-child(1) {
    display: block;
  }
  & > div:nth-child(2) {
    display: block;
  }
  & > div:nth-child(3) {
    display: block;
  }
  & > div:nth-child(4) {
    display: block;
  }

  @media screen and (min-width: 900px) {
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
  }
}

.shrink {
  & > div {
    display: block;
  }
}

.delimiter {
  width: 100%;
  height: 2px;
  background-color: #9b9a9a;
  margin-bottom: 60px;
}

//popup styles custom
.popupCont {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.popupContentCont__img {
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  width: 172.8px;
  height: 234px;

  @media screen and (min-width: 600px) {
    width: 192px;
    height: 260px;
    margin-bottom: 20px;
  }
}

.popupContentCont__DtextCont {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  width: 100%;

  @media screen and (min-width: 600px) {
    display: flex;
  }
}

.popupContentCont__MtextCont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    display: none;
  }
}

.popupContentCont__title {
  font-size: 16px;
  font-weight: 700;
  margin: 20px 0 13px 0;
  width: 230px;
  color: #132142;

  @media screen and (min-width: 600px) {
    text-align: start;
  }
}

.popupContentCont__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #132142;
  margin: 0;
  width: 230px;

  @media screen and (min-width: 600px) {
    text-align: start;
  }
}

.popupLinkContD {
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 600px) {
    display: flex;
    align-items: center;
  }
}

.popupLinkContM {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 600px) {
    display: none;
  }
}

.popupLinkCont__innerCont {
  width: fit-content;
}

.popupLinkCont__tile {
  display: block;
  font-size: 16px;
  color: #132142;
  font-weight: 700;
  margin-bottom: 16px;
}

.popupLinkInnerCont {
  width: fit-content;
  display: flex;
}

.popupLinkWhastapp {
  margin-right: 5px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  border-radius: 50%;
  cursor: pointer;
}

.popupLinkWhastappIcon {
  display: flex;
  font-size: 32px;
  color: white;
}

.popupLinkEmail {
  margin-left: 5px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  border-radius: 50%;
  cursor: pointer;
}

.popupLinkEmailIcon {
  display: flex;
  font-size: 32px;
  color: white;
}

//popup form
.popupForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alertInputs {
  width: 100% !important;
  margin: 20px 0 0px 0 !important;
  background-color: #eeeeee !important;
  border: 2px solid #d9d9d9 !important;

  &::placeholder {
    color: #9b9a9a !important;
  }

  &:focus {
    border: 2px solid #b4dbed !important;
    box-shadow: inset 0px 1px 1px rgb(0 0 0 / 6%), 0 0 0px 1px rgb(100 150 200 / 50%) !important;
  }
}

.alertTextArea {
  resize: vertical !important;
}

.swal2TtitleCustom {
  font-size: 26px !important;
  font-weight: 600 !important;
  color: #132142 !important;
}
