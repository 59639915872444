.contact {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 60px 0px;
  background-color: #142143;

  @media screen and (min-width: 900px) {
    padding: 0;
    height: max(90vh, 716px);
  }

  &__innerCont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 7px 0px;

    @media screen and (min-width: 900px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__mapCont {
    width: 100%;
    height: 280px;
    margin-bottom: 60px;

    @media screen and (min-width: 900px) {
      height: 100%;
      width: 48%;
      margin-bottom: 0px;
    }
  }

  &__formCont {
    padding: 10px 20px;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 900px) {
      padding: 20px;
      width: 48%;
    }
  }
}
