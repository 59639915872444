.flexWrapper {
  display: flex;
  flex-flow: row nowrap;
  width: 280px;
  height: 280px;
  position: relative;
}

.singleChart {
  width: 100%;
  justify-content: space-around;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circularChart {
  display: block;
  margin: 10px auto;
  max-width: 280px;
  height: auto;
}

.circleBg {
  fill: none;
}

.circle {
  fill: none;
  stroke-width: 0.6;
  animation: progress 2s ease-in-out forwards;
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
}

.circularChart.orange .circle {
  stroke: #ffce29;
}

.insideCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  background-color: #152244;
  z-index: 100;
}

.logo {
  max-width: 60px;
  height: auto;
  opacity: 0;
}

.logoAnimation {
  animation: fadeIn 1s ease-in-out forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
