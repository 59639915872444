.socialLinkIcon {
  display: flex;
  align-items: center;
  margin: 0 20px 30px 20px;

  @media screen and (min-width: 900px) {
    margin: 0 10px 0px 10px;
  }

  &__icon {
    width: 25px;
    height: auto;
    margin-right: 5px;
  }

  &__text {
    display: block;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    color: #fff;
    margin-bottom: 0;
    width: fit-content;

    @media screen and (min-width: 1500px) {
      font-size: 16px;
    }

    @media screen and (min-width: 1800px) {
      font-size: 18px;
    }
  }
}

.inDrawer {
  margin: 0;
  margin-bottom: 5px;
  img {
    width: 35px;
    height: 35px;
  }
}
