.bannerTitle {
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  text-align: left;
  margin-bottom: 20px;
  text-transform: uppercase;

  @media screen and (min-width: 600px) {
    font-size: 42px;
  }

  @media screen and (min-width: 900px) {
    font-size: 52px;
  }
}
