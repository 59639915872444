.FAQCardSet {
  width: min(100%, 1100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 900px) {
    justify-content: space-between;
    flex-direction: row;

    & div:nth-child(1) {
      width: 220px;
    }

    & div:nth-child(2) {
      width: 330px;
    }

    & div:nth-child(3) {
      width: 220px;
    }
  }
}
