.BannerParagraph {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-align: left;
  margin-bottom: 10px;
  line-height: 25px;
  width: min(100%, 500px);

  @media screen and (min-width: 600px) {
    font-size: 18px;
  }
}
