.mainImg {
  max-width: 265px;
  height: 480px;
  object-fit: cover;
  object-position: center;
  border-radius: 50px;
  z-index: 1;

  @media screen and (min-width: 900px) {
    margin-left: 60px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 300px;
    height: 520px;
  }

  @media screen and (min-width: 1800px) {
    max-width: 390px;
    height: 600px;
  }
}
