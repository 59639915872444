.sliderButton {
  width: 25px;
  height: 10px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #b4b4b4;

  @media screen and (min-width: 1800px) {
    width: 30px;
    height: 12px;
    margin: 0 12px;
    border-radius: 8px;
  }
}

.activeBtn {
  background-color: #e0b32d;
}

.faq {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
