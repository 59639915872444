.description {
  width: min(370px, 100%);
  margin-bottom: 40px;

  &__text {
    font-size: 24px;
    line-height: 1.3;
    color: #132142;
    font-weight: 500;
    letter-spacing: -2px;

    & span {
      font-weight: 600;
    }

    @media screen and (min-width: 1800px) {
      font-size: 26px;
    }
  }

  @media screen and (min-width: 900px) {
    padding-top: 150px;
  }

  @media screen and (min-width: 1800px) {
    width: 420px;
  }
}
