.FAQTemplate {
  display: grid;
  grid:
    [row1-start] 'header' max(70px, 10vh) [row1-end]
    [row2-start] 'FAQMain' calc(100vh - max(70px, 10vh)) [row2-end]
    / auto;
  min-height: 100%;
  justify-items: center;
  align-items: center;
}
