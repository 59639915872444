.mainHeader {
  background-color: #f8f8f8;
  box-shadow: -50px 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 100;
  grid-area: header;
  position: fixed;
  width: 100%;

  &__innerCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: max(70px, 10vh);
    width: 100%;
    padding: 0px 30px 0 30px;
    max-width: 1730px;
    margin: 0 auto;

    @media screen and (min-width: 600px) {
      padding: 0px 70px 0 70px;
    }

    @media screen and (min-width: 1200px) {
      padding: 0px 90px 0 90px;
    }

    @media screen and (min-width: 1800px) {
      padding: 0px;
    }
  }
}
