.FAQSlider {
  grid-area: FAQMain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &__ContentCont {
    width: 100%;
    position: relative;
    min-height: 75vh;
    margin-top: 120px;
    overflow: hidden;

    @media screen and (max-height: 500px) {
      min-height: 100vh;
      overflow-y: hidden;
    }

    @media screen and (max-height: 420px) {
      min-height: 230vh;
      overflow-y: hidden;
    }

    @media screen and (min-width: 600px) {
      margin-top: 0px;
    }
  }

  &__preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    transition: all 1s ease-in-out;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-align: center;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    transition: all 1s ease-in-out;

    @media screen and (min-width: 600px) {
      padding: 0;
      width: 450px;
    }

    @media screen and (max-height: 400px) {
      height: 620px;
    }
  }

  &__SliderCont {
    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
  }

  &__next {
    margin-top: 25px;
    height: 50px;
    width: 50px;
    margin-bottom: 25px;

    div {
      height: 100%;
      width: 100%;
      transform: rotateY(180deg);
      transition: all 0.15s linear;

      &:active {
        background-color: #d9d9d9;
      }

      &:hover {
        img {
          transition: all 0.05s linear;
          filter: invert(72%) sepia(76%) saturate(472%) hue-rotate(354deg) brightness(92%)
            contrast(89%);
        }
      }
    }
  }

  &__toForm {
    margin-top: 25px;
    margin-bottom: 25px;
    height: 50px;
    text-align: center;
    transition: all 1s ease-in-out;
  }
}

.previewLeft {
  left: -100%;
}

.formLeft {
  left: 50%;

  transform: translate(-50%, 0px);

  @media screen and (max-height: 600px) {
    top: 10%;
  }

  @media screen and (max-height: 500px) {
    top: 20%;
  }

  @media screen and (max-height: 400px) {
    top: 45%;
  }
}

.newHeight {
  min-height: 100vh;

  @media screen and (max-height: 600px) {
    min-height: 800px;
  }

  @media screen and (max-height: 500px) {
    min-height: 1200px;
  }
}

.fadeOut {
  animation: FadeOutAndGoLeft 1s ease-in-out forwards;

  @keyframes FadeOutAndGoLeft {
    0% {
      opacity: 1;
      transform: translateX(0px);
    }

    50% {
      opacity: 0;
      height: 0;
      transform: translateX(0px);
    }

    100% {
      opacity: 0;
      height: 0;
      transform: translateX(-1000%);
    }
  }
}
