.description {
  color: #132142;
  text-align: center;
  line-height: 1.2;
  font-size: 16px;

  @media screen and (min-width: 600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 900px) {
    font-size: 20px;
  }
}
