.productsLabel {
  display: block;
  margin: 0;
  font-weight: 600;
  padding: 5px 10px;
  color: #132142;
  background-color: #e0b32d;
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;

  &__inDrawer {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

.inProductsCard {
  background-color: transparent;
  text-align: center;
  color: #dde4f8;
  font-size: 18px;
}
