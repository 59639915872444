.productDrawer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 20px;

  @media screen and (min-width: 600px) {
    display: none;
  }

  &__navbar {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    right: 0px;
    top: 0px;
    width: 75vw;
    height: 100vh;
    z-index: 100;
    box-shadow: -1px 0px 1px hsl(0, 0%, 0%, 20%);
    background-color: #f8f8f8;
    transition: var(--transition);
  }

  &__show {
    transform: translateX(0);
  }

  &__hide {
    transform: translateX(105%);
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 90px;
    margin-top: max(120px, 10vh);
    border-top: 1px solid #132142;
    border-bottom: 1px solid #132142;
  }

  &__home {
    width: 48%;
    height: 100%;
  }

  &__goBack {
    width: 48%;
    height: 100%;
  }

  &__categories {
    display: flex;
    flex-direction: column;
    height: 65vh;
    width: 100%;
    margin: auto 0;
    overflow: auto;
  }

  &__categoryCont {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-bottom: 1px solid #c0c1c4;

    & a {
      margin-top: 20px;
    }

    & span {
      margin-bottom: 20px;
    }
  }

  &__category {
    width: fit-content;
    margin-bottom: 20px;
  }

  &__focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 95;
  }

  &__showFocus {
    display: block;
    background-color: hsl(0, 0%, 0%, 20%);
    backdrop-filter: blur(5px);
    animation: show 0.5s ease-in-out forwards;
  }

  &__hideFocus {
    display: none;
    opacity: 0;
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
