.viewProductsButton {
  padding: 12px 25px;
  background-color: #e0b32d;
  border: 1px solid #e0b32d;
  font-size: 18px;
  color: #132142;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  font-family: inherit;
  z-index: 10;
  cursor: pointer;
  margin-bottom: 40px;
  text-transform: uppercase;
}
