.productCategoryImg {
  height: 210px;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  filter: grayscale(50%);
}
